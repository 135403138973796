import React from "react"
import Layout from '../components/layout-en'
import Head from '../components/head'
import '../styles/what-we-do.css'
import {Row, Col} from 'react-bootstrap'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"


import rightArrow from '../images/long-arrow-right.png'
import FarmerImg from '../images/farmer-img.png'
import InputsCompanyImg from '../images/inputs-company-img.png'
import CropInsuranceImg from '../images/crop-insurance-img.png'
import FarmingCompanyImg from '../images/farming-company-img.png'
import SeedProducerImg from '../images/seed-producer-img.png'
import FinancialInstitution from '../images/financial-support-img.png'
import GovernmentImg from '../images/government-img.png'
import Vegetables from '../images/vegetables.png'
import Cabbages from '../images/cabbage.png'
import OrangeTree from '../images/orange_tree.png'

class WhatWeDo extends React.Component {

  /*constructor(props){
    super(props);
    
  }*/

  render(){

    return(
        <Layout>
          <Head title = "What We Do"/>
          
          <div id = "wwd-page-container">
            <div id = "wwd-section-1">
                <div id = "wwd-section-1-content">
                  <div id = "wwd-section-1-content-title">Our Approach</div>
                  <div id = "wwd-section-1-content-paragraph">
                    Empowering farmers and companies to growing more, reaching more, and distributing better. 
                </div>
              </div>
            </div>

            <div id = "wwd-page-nav">
              <div id = "wwd-nav-item-container">
                <div className = "wwd-nav-item nav-active">Our Approach</div>
                <div className = "wwd-nav-item">Partnership</div>
                <div className = "wwd-nav-item">Enterprise</div>
              </div>
            </div>

            <div id = "wwd-section-2">
              <div id = "wwd-section-2-content">
                  <div id = "section-2-content-title">
                  Let’s build a more resilient and sustainable agriculture together.
                  </div>
                  <div id = "section-2-content-paragraph">
                      <p>
                        Human population is climbing steadily toward 10 billion by 2050 (FAO) and the 
                        demand for food is increasing rapidly. All in the midst of unprecedented climate 
                        change that will tax even the most resilient systems. Today’s food and agricultural 
                        systems are accountable for a quarter of the world’s greenhouse gas emissions and 70% of global freshwater use. Those same systems deplete soils, water quality, and natural habitats all across the world. At the same time, up to 40% of global yield is lost due crops disease and pest annually. This is a threat for global food security.
                      </p>

                      <p>
                        It is clear that we need a more resilient and sustainable food system. And to achieve it, 
                        we realized that it would take a collective efforts from various stakeholders: universities, 
                        government institutions, and communities.
                      </p>
                  </div>
              </div>
            </div>

            <div id = "wwd-section-3">
              <div id = "wwd-section-3-content">
                <div className = "wwd-section-3-box-container">
                  <div className = "wwd-section-3-box">
                      <StaticImage src = {FarmerImg} alt = "farmer-img"/>
                      <div className = "wwd-section-3-box-title">Farmer</div>
                  </div>
                  <div className = "wwd-section-3-box">
                      <StaticImage src = {InputsCompanyImg} alt = "inputs-company-img"/>
                      <div className = "wwd-section-3-box-title">Inputs Company</div>
                  </div>
                </div>

                <div className = "wwd-section-3-box-container">
                  <div className = "wwd-section-3-box">
                      <StaticImage src = {CropInsuranceImg} alt = "crop-insurance-img"/>
                      <div className = "wwd-section-3-box-title">Crop Insurance</div>
                  </div>
                  <div className = "wwd-section-3-box">
                      <StaticImage src = {FarmingCompanyImg} alt = "Farming-company-img"/>
                      <div className = "wwd-section-3-box-title">Farming Company</div>
                  </div>
                  <div className = "wwd-section-3-box">
                      <StaticImage src = {SeedProducerImg} alt = "seed-produce-img"/>
                      <div className = "wwd-section-3-box-title">Seed Producer</div>
                  </div>
                </div>

                <div className = "wwd-section-3-box-container">
                  <div className = "wwd-section-3-box">
                      <StaticImage src = {FinancialInstitution} alt = "financial-institution-img"/>
                      <div className = "wwd-section-3-box-title">Financial institution</div>
                  </div>
                  <div className = "wwd-section-3-box">
                      <StaticImage src = {GovernmentImg} alt = "inputs-company-img"/>
                      <div className = "wwd-section-3-box-title">Government</div>
                  </div>
                </div>
              </div>
            </div>

            <div id = "wwd-section-4">
              <div id = "wwd-section-4-header">
                <div id = "wwd-section-4-header-title">Solving Agriculture Through Technology</div>
                <div id = "wwd-section-4-header-paragraph">
                  We believe that unlocking the power of data and technology in the Agriculture 
                  industry could help us to provide food for everyone without compromising the future generations.
                </div>
              </div>

              <div id = "wwd-section-4-body">
                <div id = "wwd-section-4-body-card-container">
                  <div className = "wwd-section-4-card">

                    <div className = "wwd-section-4-card-img">
                      <StaticImage src = {FarmerImg} alt = "farmer-img"/>
                    </div>

                    <div className = "wwd-section-4-card-title">Farmer</div>
                    
                    <ul>
                      <li>
                        <span>Improve Knowledge</span>
                      </li>
                      <li>
                        <span>Reduce Risk</span>
                      </li>
                      <li>
                        <span>Improve Yield</span>
                      </li>
                      <li>
                        <span>Inspire next-gen farmers</span>
                      </li>
                    </ul>

                  </div>

                  <div className = "wwd-section-4-card">

                    <div className = "wwd-section-4-card-img">
                      <StaticImage src = {FarmingCompanyImg} alt = "farming-company-img"/>
                    </div>

                    <div className = "wwd-section-4-card-title">Farming Company</div>
                    
                    <ul>
                      <li>
                        <span>Lower Costs</span>
                      </li>
                      <li>
                        <span>Improve Productivity</span>
                      </li>
                      <li>
                        <span>Efficient Operations</span>
                      </li>
                    </ul>

                  </div>

                  <div className = "wwd-section-4-card">

                    <div className = "wwd-section-4-card-img">
                      <StaticImage src = {GovernmentImg} alt = "government-img"/>
                    </div>

                    <div className = "wwd-section-4-card-title">Government</div>
                    
                    <ul>
                      <li>
                        <span>Better Policy</span>
                      </li>
                      <li>
                        <span>Ensure Food Security</span>
                      </li>
                      <li>
                        <span>Improve Farmer Welfare</span>
                      </li>
                    </ul>

                  </div>

                </div>

                
              </div>

              <div id = "partnering-with-us">
                <Link to="/what-we-do" id = "partnering-with-us-link">
                  Learn More About Partnering With Us
                  <StaticImage src = {rightArrow} id = "right-arrow" alt = "right-arrow" width = "28px"/>
                </Link>
              </div>

            </div>

            <div id = "wwd-section-5">
                <Row>
                  <Col md = {{span : 5, offset : 1}} id = "wwd-section-5-col-1">
                    <div id = "wwd-section-5-col-1-title">Increase Yield and Productivity Today</div>
                    
                    <Link to = "contact-us" id = "wwd-section-5-button">Talk To Us</Link>
                      
                  </Col>

                  <Col md = {{span : 4, offset : 2}} id = "wwd-section-5-col-2">
                      <StaticImage src = {Vegetables} alt = "vegetables-img" id = "vegetables-img"/>
                      <StaticImage src = {OrangeTree} alt = "OrangeTree-img" id = "orange-tree-img"/>
                      <StaticImage src = {Cabbages} alt = "cabbages-img" id = "cabbages-img"/>
                  </Col>
                </Row>
              </div>

          </div>
        </Layout>
    ) 
  }
}

export default WhatWeDo;